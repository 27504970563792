<template>
  <v-container class="pa-3" id="logs">
    <v-data-table
      :footer-props="tablePagination"
      :headers=headers
      :items=logs
      :items-per-page.sync=itemsPerPage
      :page.sync=page
      :server-items-length="logCount"
      class="flex"
    >
      <template v-slot:top>
        <span class="text-h2">
          {{ labels.lastChanges }}
        </span>
        <v-text-field
          v-model="logFilter"
          append-icon="mdi-magnify"
          :label="labels.search"
          single-line
          hide-details
          :disabled="loading"
        />
      </template>
      <template v-slot:item.created="{ value }">
        {{ formatDate(value) }}
      </template>
      <template v-slot:item.environment.name="{ item, value }">
        <v-chip
          v-if="value"
          color=primary
          label
          :to="{
            name: 'environment',
            params: { 'name': value, }
          }"
        >
          {{ value }}
        </v-chip>
      </template>
      <template v-slot:item.message="{ item, value }">
        <span :class="item.is_error ? 'error--text' : 'info--text'">
          {{ value }}
        </span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { debounce } from 'lodash';
import { labels } from '@/assets/texts.json';
import { formatDate } from '@/helpers';

export default {
  name: 'logs',
  created() {
    this.fetchData();
  },
  computed: {
    ...mapState('fetch', [
      'logCount',
      'logs',
    ]),
    ...mapState('shared', [
      'loading',
    ]),
  },
  data: () => ({
    headers: [
      {
        text: 'Date',
        value: 'created',
        sortable: true,
      },
      {
        text: 'Environment',
        value: 'environment.name',
        sortable: true,
      },
      {
        text: 'Provider',
        value: 'provider.name',
        sortable: true,
      },
      {
        text: 'User',
        value: 'user.username',
        sortable: true,
      },
      {
        text: 'Message',
        value: 'message',
        sortable: true,
      },
    ],
    itemsPerPage: 10,
    labels,
    logFilter: null,
    page: 1,
    tablePagination: { 'items-per-page-options': [10, 20, 50, -1] },
  }),
  methods: {
    ...mapActions({
      displayMsg: 'shared/displayMsg',
      errorMsg: 'shared/errorMsg',
      fetchLogs: 'fetch/fetchLogs',
    }),
    fetchData() {
      return this.fetchLogs({
        itemsPerPage: this.itemsPerPage,
        logFilter: this.logFilter,
        page: this.page,
      });
    },
    formatDate,
    getPaginationSize(number) {
      return parseInt(
        number === this.labels.all ? this.logCount : number,
        10,
      );
    },
    timestampToDate: (timestamp) => (
      new Date(timestamp * 1000).toLocaleString()
    ),
  },
  watch: {
    itemsPerPage: function itemsPerPage() {
      this.fetchData();
      this.page = 1;
    },
    logFilter: debounce(function debouncedFetch() {
      this.fetchData()
        .finally(() => { this.page = 1; });
    }, 500), // timeout (ms)
    page: function page() {
      console.log(this.page);
      this.fetchData();
    },
  },
};
</script>

<style scoped>
</style>
