var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-3",attrs:{"id":"logs"}},[_c('v-data-table',{staticClass:"flex",attrs:{"footer-props":_vm.tablePagination,"headers":_vm.headers,"items":_vm.logs,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"server-items-length":_vm.logCount},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('span',{staticClass:"text-h2"},[_vm._v(" "+_vm._s(_vm.labels.lastChanges)+" ")]),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.labels.search,"single-line":"","hide-details":"","disabled":_vm.loading},model:{value:(_vm.logFilter),callback:function ($$v) {_vm.logFilter=$$v},expression:"logFilter"}})]},proxy:true},{key:"item.created",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}},{key:"item.environment.name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(value)?_c('v-chip',{attrs:{"color":"primary","label":"","to":{
          name: 'environment',
          params: { 'name': value, }
        }}},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e()]}},{key:"item.message",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
return [_c('span',{class:item.is_error ? 'error--text' : 'info--text'},[_vm._v(" "+_vm._s(value)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }